.card-container {
  overflow: hidden;
  display: flex;
  max-height: 600px;
  max-width: 550px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  img {
    height: 100%;
    width: 100%;
    object-fit: 'cover';
  }
}