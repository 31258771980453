.icon-containter {
  display: flex;
  overflow: hidden;
  max-width: 50px;
  max-height: 50px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}

@media screen and (min-width: 1500px) {
  .icon-containter {
    max-width: 90px;
    max-height: 90px;
  }
}