.footer-wrapper {
  display: flex;
  justify-content: center;
  background-color: #f8f9fa;
  flex-direction: column;
  align-items: center;
}

.footer-body {
  display: flex;
  flex-direction: column;
  gap: 16;
  align-items: center;
  margin-top: 56px;
}

.footer-text {
  font-size: 16;
  color: gray;
  font-weight: 700;
}