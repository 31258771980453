.card-new-wrapper {
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	max-width: 350px;
	box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
	background-color: #fff;
	max-height: 500px;
	gap: 24px;
}

.card-new-title {
	margin: 40px 24px 0px 24px;
	display: flex;
	// justify-content: center;
	font-size: 24px;
}

.card-new-body {
	display: flex;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	height: 100%;
	flex-direction: column;
	color: gray;
	font-size: 18px;
}

.card-new-body-container {
	margin: 8px 24px 48px 24px;
	border-top: 2px solid maroon;
	padding-top: 32px;
}

.card-new-text-body {
    margin-top: 24px;
}

@media screen and (min-width: 1500px) {
	.card-new-wrapper {
			max-width: 450px;
	}
}