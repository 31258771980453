.services-wrapper {
    display: flex;
    justify-content: center;
    color: #2d2e2e;
    font-family: var(--zds-typography-heading, "Degular", Helvetica, arial, sans-serif);
    margin-bottom: 80px;
    margin-top: 24px;
}

.services-body {
    display: flex;
    width: 80%;
    gap: 32px;
    justify-content: space-between;
}

@media screen and (max-width: 500px) {
  .services-body {
    width: 90%;
    justify-content: center;
    flex-wrap: wrap;
  }
}