.stack-wrapper {
    display: flex;
    justify-content: center;
    // background-color: #f8f9fa;
}

.tooltip {
    visibility: hidden;
}

.tooltip:hover {
    visibility: visible;
}

.stack-body {
    width: 80%;
    display: flex;
    margin: 24px 0px 88px;
    gap: 96px;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width: 1000px) {
    .stack-body {
        justify-content: center;
        width: 90%;
        margin: 24px 0;
        gap: 48
    }
}